/**
 * Exports an object containing the addresses to the apps used across the entire site
 */
export const bounceAppUrls = {
  ios: "https://apps.apple.com/app/bounce-bag-storage-everywhere/id1444570755",
  android: "https://play.google.com/store/apps/details?id=com.usebounce",
}

/**
 * Exports an object containing the addresses to the partner apps used across the entire site
 */
export const bouncePartnerAppUrls = {
  ios: "https://apps.apple.com/us/app/bounce-partner/id1610526781",
  android: "https://play.google.com/store/apps/details?id=com.usebounce.partner",
}

/**
 * Exports an object containing the addresses to the Package Pickup app used across the entire site
 */
export const bouncePaAppUrls = {
  ios: "https://apps.apple.com/us/app/bounce-package-pickup/id6446174529",
  android: "https://play.google.com/store/apps/details?id=com.usebounce.packages",
}
