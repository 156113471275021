import queryString from "query-string"

import { bounceAppUrls, bouncePaAppUrls, bouncePartnerAppUrls } from "@bounce/util"
import { isApple } from "@bounce/web-components"

const APPS = {
  partner: bouncePartnerAppUrls,
  packages: bouncePaAppUrls,
  luggageStorage: bounceAppUrls,
}

type GetCampaignQueryParamsOptions = {
  platform: "ios" | "android"
  campaignName: string
}

export type GetAppStoreUrlOptions = {
  app: "luggageStorage" | "packages" | "partner"
  platform?: "ios" | "android"
  campaignName?: string
}

const getPreferredPlatform = (platform: GetAppStoreUrlOptions["platform"]) => {
  if (!platform) return isApple() ? "ios" : "android"
  return platform
}

const getCampaignQueryParams = ({ platform, campaignName }: GetCampaignQueryParamsOptions) => {
  if (platform === "ios") {
    return {
      ct: campaignName,
      mt: "8",
      pt: "119432151",
    }
  }

  return {
    utm_source: "bounce",
    utm_medium: "marketing",
    utm_campaign: campaignName,
  }
}

/**
 * Generate and return a URL for the specified app in the App Store, tailored to the user's platform.
 * If no platform is specified, the function will auto-detect the user's platform and return the URL accordingly.
 * The function also includes campaign tracking parameters to track the performance of various marketing campaigns.
 *
 * @param {GetAppStoreUrlOptions} options - Configuration options for the function.
 * @param {string} options.app - The bounce app for which to generate a store URL. Can be "luggageStorage", "packages", or "partner".
 * @param {string} [options.platform] - The platform for which to generate the URL. Can be "ios" or "android". If not specified, it will be auto-detected.
 * @param {object} options.campaign - An object containing the tracking parameters for the marketing campaign. This differs for iOS and Android.
 * @returns {string} The fully assembled App Store URL, including campaign tracking parameters.
 */
export const getAppStoreUrl = ({ app, platform, campaignName = "bounce-marketing-ctas" }: GetAppStoreUrlOptions) => {
  const preferredPlatform = getPreferredPlatform(platform)
  const url = APPS[app][preferredPlatform]
  const query = getCampaignQueryParams({ platform: preferredPlatform, campaignName })

  const appStoreUrl = queryString.stringifyUrl({
    url,
    query,
  })

  return appStoreUrl
}
