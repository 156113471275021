import UAParser from "ua-parser-js"

const APPLE_OS = ["Mac OS", "iOS"]

const getOSName = () => {
  try {
    const parser = new UAParser(window.navigator.userAgent)
    const parsedUA = parser.getResult()

    return parsedUA.os.name ?? ""
  } catch (error) {
    // Unsupported/Blocked by the browser
    return ""
  }
}

/**
 * Return true if the os is an apple one
 */
export const isApple = () => APPLE_OS.includes(getOSName())
