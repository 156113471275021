import classNames from "classnames"
import Image from "next/image"

import { Analytics } from "@bounce/analytics"
import { Paragraph2, Title6 } from "@bounce/design-system"
import { useTranslation } from "@bounce/i18n"
import { Button } from "@bounce/web-components"

import { BANNER_VARIANT, useAppStickyBanner, useIsAppStickyBannerVisible } from "../../../contexts/AppStickyBanner"
import { useAppStoreUrl } from "../../../hooks/useAppStoreUrl"
import { InteractionEvents } from "../../../libs/analytics/events"

import CloseIcon from "@bounce/assets/icons/Cross.svg"
import PhoneUiImage from "@bounce/assets/illustrations/phone-ui-01@2x.webp"

const AppStickyBanner = () => {
  const { t } = useTranslation()
  const { href } = useAppStoreUrl({
    app: "luggageStorage",
    eventProps: { location: "AppStickyBanner", cta: "easier" },
    campaignName: "marketing-AppStickyBanner",
  })
  const visible = useIsAppStickyBannerVisible()
  const dismissBanner = useAppStickyBanner(state => state.dismissBanner)

  const ctaText = t("cmp.appStickyBanner.cta", "Use app")
  const shouldWrapText = ctaText.length && ctaText.length > 10

  const onClose = () => {
    Analytics.track(InteractionEvents.LuggageStorageAppStickyBannerDismissed, { variant: BANNER_VARIANT })
    dismissBanner()
  }

  const onPressUseApp = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    ev.preventDefault()

    Analytics.track(InteractionEvents.LuggageStorageAppStickyBannerCTAPressed, { variant: BANNER_VARIANT })

    location.assign(ev.currentTarget.href)
  }

  if (!visible) {
    return null
  }

  return (
    <div className="flex max-w-full flex-row justify-between gap-2 border-b-[1px] border-neutral-300 bg-white p-4">
      <div className={classNames("flex grow flex-row justify-between gap-2", shouldWrapText && "flex-wrap")}>
        <div className="flex flex-row items-center gap-2">
          <Image className="h-full w-16 shrink grow-0" src={PhoneUiImage} alt="image" />
          <div>
            <Title6 className="text-sm">{t("cmp.appStickyBanner.easier.title", "It's easier on the app!")}</Title6>
            <Paragraph2 className="text-neutral-700">
              {t("cmp.appStickyBanner.easier.description", "Access your booking on the go.")}
            </Paragraph2>
          </div>
        </div>
        <div className="flex flex-row items-center gap-3">
          <Button href={href} className="min-h-0 px-3 py-2 text-sm" onClick={onPressUseApp}>
            {ctaText}
          </Button>
        </div>
      </div>
      <button onClick={onClose}>
        <CloseIcon className="h-5 w-5" />
      </button>
    </div>
  )
}

export default AppStickyBanner
