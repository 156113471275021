import classNames from "classnames"
import React from "react"

import { useIntercom } from "@bounce/intercom"
import { useReferrals } from "@bounce/referrals"

import { useIsAppStickyBannerVisible } from "../../../contexts/AppStickyBanner"
import { Breadcrumbs } from "../../Generic/Breadcrumbs/Breadcrumbs"
import { AppBanner } from "../AppBanner/AppBanner/AppBanner"
import AppStickyBanner from "../AppStickyBanner/AppStickyBanner"
import { Navbar } from "../Navbar/Navbar"
import NewsletterBanner from "../NewsletterBanner/NewsletterBanner"
import { PromotionBanner } from "../PromotionBanner/PromotionBanner"
import type { FooterProps } from "@/components/Generic/Footer/Footer"
import { Footer } from "@/components/Generic/Footer/Footer"
import type { HeadProps } from "@/components/Generic/Head"
import { Head } from "@/components/Generic/Head"
import { config } from "@/config"
import type { AlternatePath, Breadcrumb } from "@/types"

const ACTIVE_LANGUAGES = Object.values(config.locales)
  .filter(locale => locale.active)
  .map(locale => locale.language)

export type LayoutProps = {
  alternates?: AlternatePath[]
  breadcrumbs?: Breadcrumb[]
  children?: React.ReactNode
  footerProps?: FooterProps
  from?: "Home" | "City" | "Poi" | "AppDownload"
  headProps?: HeadProps
  hideNewsletterBanner?: boolean
  hidePromotionBanner?: boolean
  languages?: string[]
  promotionBanner?: boolean
  searchQuery?: string
}

const NavbarSection = ({
  alternates,
  hidePromotionBanner,
  languages = ACTIVE_LANGUAGES,
  promotionBanner,
}: Pick<LayoutProps, "alternates" | "hidePromotionBanner" | "languages" | "promotionBanner">) => {
  const stickyBannerVisible = useIsAppStickyBannerVisible()
  const { coupon } = useReferrals()
  const showPromotionBanner = (promotionBanner || !!coupon) && !hidePromotionBanner

  return (
    <>
      <div className={classNames("top-0 z-20", stickyBannerVisible && "sticky")}>
        <AppStickyBanner />
      </div>
      <div className={classNames("bg-white", !stickyBannerVisible && "sticky top-0 z-10")}>
        <Navbar alternates={alternates} languages={languages} />
        {showPromotionBanner && <PromotionBanner coupon={coupon} />}
      </div>
    </>
  )
}

export const Layout = ({
  alternates,
  breadcrumbs,
  children,
  footerProps,
  from,
  headProps,
  hideNewsletterBanner,
  hidePromotionBanner,
  languages = ACTIVE_LANGUAGES,
  promotionBanner,
}: LayoutProps) => {
  useIntercom({
    appId: config.intercom.luggageStorageAppId,
    defer: true,
    supportedEvents: [],
  })
  const showNewsletterBanner = !hideNewsletterBanner
  const headAlternates = alternates?.map(({ language, href }) => (
    <link key={language} rel="alternate" href={href} hrefLang={language} />
  ))

  return (
    <>
      <Head {...headProps} alternates={headAlternates} languages={languages} />
      <NavbarSection {...{ languages, alternates, promotionBanner, hidePromotionBanner }} />
      <main className="flex w-full flex-1 flex-col items-center">{children}</main>
      {showNewsletterBanner && <NewsletterBanner />}
      <AppBanner
        id="appDownload"
        name={"AppBannerFooter" + from}
        className={classNames({ "bg-white": showNewsletterBanner })}
      />
      {breadcrumbs && (
        <section className="bg-neutral-200">
          <Breadcrumbs
            breadcrumbs={breadcrumbs}
            className="mx-auto w-full max-w-screen-xl flex-wrap p-6"
            linkClassName="underline"
          />
        </section>
      )}
      <Footer {...footerProps} />
    </>
  )
}
