import React from "react"

import { Trans, useTranslation } from "@bounce/i18n"
import { AppBanner as BaseAppBanner } from "@bounce/web-components"

import { useAppStoreUrl } from "../../../../hooks/useAppStoreUrl"

import illustration from "@bounce/assets/illustrations/phone-bags.png"

export type AppBannerProps = {
  id?: string
  name?: string
  className?: string
}

export const AppBanner = ({ id, name, className }: AppBannerProps) => {
  const { t } = useTranslation()
  const { href, onClick } = useAppStoreUrl({
    app: "luggageStorage",
    eventProps: { location: "AppBannerLuggageStorage", cta: name },
  })

  return (
    <BaseAppBanner
      title={
        <Trans
          i18nKey="cmp.appBannerLuggageStorage.titleV6"
          defaults="Get the <br />Bounce app"
          components={{
            br: <br />,
          }}
        />
      }
      subtitle={t(
        "cmp.appBannerLuggageStorage.description",
        "Instantly find locations nearby to drop off your luggage wherever you go.",
      )}
      src={illustration}
      ctaHref={href}
      ctaOnClick={onClick}
      ctaRel="nofollow"
      ctaLabel={t("cmp.appBannerLuggageStorage.cta", "Download")}
      className={className}
      id={id}
    />
  )
}
