export type LinkTarget = "_blank" | "_parent" | "_self" | "_top"

/**
 * Get bounce marketing website URL from path and language
 * @example
 * getMarketingURL({language: "en", path: "reviews"}) // https://usebounce.com/reviews
 */
export const getMarketingURL = (route: { language?: string; path: string }) => {
  let url = "https://usebounce.com"
  // language prefix
  if (route.language) url += "/" + route.language

  // Sanitize the path who start by "/", "?", "#"
  if (/^[#?/]/.test(route.path)) {
    url += route.path
  } else {
    url += "/" + route.path
  }

  const endBySlash = /\/$/
  url = url.replace(endBySlash, "")

  return url
}

export const isPartnerAppUrl = (url: string, partnerUrl: string) => {
  return url.startsWith(partnerUrl)
}

/**
 * Open an url
 * @example
 * openURL("https://www.google.com", "_blank")
 */
export const openURL = (url: string, target: LinkTarget = "_blank") => window.open(url, target)

/**
 * Check if the user is from a adwords campaign
 * @example
 * // /?utmsource=google
 * isFromAdwords() // false
 * // /?utmsource=adwords
 * isFromAdwords() // true
 */
export const isFromAdwords = () => {
  const params = new URLSearchParams(window.location.search)
  const isAdwords =
    params.get("utmsource") === "adwords" ||
    params.get("utm_source") === "adwords" ||
    params.get("hsanet") === "adwords" ||
    params.get("hsa_net") === "adwords"

  return isAdwords
}
