import { createZustandStore } from "@bounce/state-management"
import { useIsMobile } from "@bounce/web-components"

type AppStickyBannerState = {
  visible: boolean
  dismissed: boolean
  toggleBanner: (visible: boolean) => void
  dismissBanner: () => void
}

export const BANNER_VARIANT = "easier"

export const useAppStickyBanner = createZustandStore<AppStickyBannerState>({
  name: "useAppStickyBanner",
  initializer: (set, _get) => ({
    visible: true,
    dismissed: false,
    toggleBanner: (visible: boolean) => set({ visible }),
    dismissBanner: () => set({ dismissed: true }),
    reset: () => set({ visible: true, dismissed: false }),
  }),
})

/* Only display banner if:
  - user is not on mobile web
  - user has not dismissed it
  - we hid it on purpose (e.g. when the menu is open)
*/
export const useIsAppStickyBannerVisible = () => {
  const isMobile = useIsMobile()
  const isVisible = useAppStickyBanner(state => state.visible && !state.dismissed)

  return isMobile && isVisible
}
