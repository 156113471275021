import React, { useState } from "react"

import { Analytics } from "@bounce/analytics"
import { useTranslation } from "@bounce/i18n"
import type { Nullable } from "@bounce/util"
import { openURL } from "@bounce/util"

import { config } from "../../../config/config"
import { InteractionEvents } from "../../../libs/analytics/events"
import { useSubscribeNewsletterMutation } from "../../../libs/bounce/bounceSdk.gen"
import GenericNewsletterBanner from "../../Generic/NewsletterBanner/NewsletterBanner"

import BannerImage from "@bounce/assets/illustrations/paperplane@2x.webp"

const DEFAULT_SUCCESS_URL = `https://${config.domain}/s?coupon=WELCOME10`

export type NewsletterBannerProps = {
  discountPercentage?: number
  wrapperClass?: string
  copy?: {
    preSubscribe?: {
      title?: Nullable<string | React.ReactNode>
      subtitle: Nullable<string | React.ReactNode>
      cta?: Nullable<string>
      showDisclaimer?: boolean
    }
    postSubscribe?: {
      title?: Nullable<string | React.ReactNode>
      subtitle: Nullable<string | React.ReactNode>
      cta?: Nullable<string>
    }
  }
  titleAs?: "h2" | "h3"
}

const NewsletterBanner = ({ copy, wrapperClass, discountPercentage = 10, titleAs = "h2" }: NewsletterBannerProps) => {
  const [email, setEmail] = useState<Nullable<string>>(null)
  const [error, setError] = useState<Nullable<string>>(null)
  const { t } = useTranslation()
  const { mutate: subscribeNewsletter, data, isPending: isLoading } = useSubscribeNewsletterMutation()

  const hasSubscribed = data?.subscribeNewsletter?.status === "success"

  const signUpToNewsletter = () => {
    if (!email) {
      setError(t("cmp.luggageStorage.newsletterBanner.enterValidEmail", "Enter a valid email address"))
      return
    }

    subscribeNewsletter(
      { input: { email } },
      {
        onSuccess: () => Analytics.track(InteractionEvents.LuggageStorageNewsletterSignedUp),
        onError: () =>
          setError(
            t("cmp.luggageStorage.newsletterBanner.couldNotSignUp", "Oops, could not sign you up. Please try again."),
          ),
      },
    )
  }

  const onBookNowPress = () => {
    Analytics.track(InteractionEvents.LuggageStorageNewsletterBookNowCtaPressed)
    openURL(DEFAULT_SUCCESS_URL)
  }

  if (hasSubscribed) {
    return (
      <GenericNewsletterBanner
        wrapperClass={wrapperClass}
        image={BannerImage}
        title={
          copy?.postSubscribe?.title ?? t("cmp.luggageStorage.newsletterBanner.successTitle", "Thanks for subscribing!")
        }
        subtitle={
          copy?.postSubscribe?.subtitle ??
          t(
            "cmp.luggageStorage.newsletterBanner.successSubtitle2",
            "Check your email to get your code for {{percentage}}% off, or book now and we'll apply your discount at checkout.",
            { percentage: discountPercentage },
          )
        }
        ctaText={
          copy?.postSubscribe?.cta ??
          t("cmp.luggageStorage.newsletterBanner.successCtaText2", "Book now with {{percentage}}% off", {
            percentage: discountPercentage,
          })
        }
        disclaimerText={null}
        displayInput={false}
        onPress={onBookNowPress}
      />
    )
  }

  return (
    <GenericNewsletterBanner
      wrapperClass={wrapperClass}
      title={
        copy?.preSubscribe?.title ?? t("cmp.luggageStorage.newsletterBanner.title", "Love discounts and traveling?")
      }
      subtitle={
        copy?.preSubscribe?.subtitle ??
        t(
          "cmp.luggageStorage.newsletterBanner.subtitle",
          "Sign up for our newsletter to get insider travel tips and a 10% discount delivered straight to your inbox.",
        )
      }
      inputPlaceholder={t("cmp.luggageStorage.newsletterBanner.inputPlaceholder", "Your email")}
      ctaText={copy?.preSubscribe?.cta ?? t("cmp.luggageStorage.newsletterBanner.ctaText", "Get 10% off")}
      disclaimerText={
        copy?.preSubscribe?.showDisclaimer !== false
          ? t("cmp.luggageStorage.newsletterBanner.disclaimerText", "No spam here, just the good stuff.")
          : undefined
      }
      image={BannerImage}
      onChange={setEmail}
      onPress={signUpToNewsletter}
      disabled={isLoading}
      errorText={error}
      titleAs={titleAs}
    />
  )
}

export default NewsletterBanner
