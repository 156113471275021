import classNames from "classnames"
import type { StaticImageData } from "next/image"
import Image from "next/image"
import React from "react"

import type { Nullable } from "@bounce/util"
import { Button, Input } from "@bounce/web-components"

export type NewsletterBannerProps = {
  title?: Nullable<string | React.ReactNode>
  subtitle?: Nullable<string | React.ReactNode>
  ctaText?: Nullable<string>
  ctaClass?: Nullable<string>
  disclaimerText?: Nullable<string>
  errorText?: Nullable<string>
  displayInput?: Nullable<boolean>
  inputPlaceholder?: Nullable<string>
  inputClass?: Nullable<string>
  wrapperClass?: Nullable<string>
  image?: Nullable<StaticImageData | string>
  disabled?: Nullable<boolean>
  onChange?: Nullable<(value: string) => void>
  onPress?: Nullable<() => void>
  titleAs?: "h2" | "h3"
}

const NewsletterBanner = ({
  title,
  subtitle,
  wrapperClass,
  ctaText,
  ctaClass,
  errorText,
  disclaimerText,
  displayInput = true,
  inputPlaceholder,
  inputClass,
  image,
  disabled,
  onChange,
  onPress,
  titleAs: TitleAs = "h2",
}: NewsletterBannerProps) => {
  const showDisclaimer = !!disclaimerText && !errorText

  return (
    <div className={classNames("w-full", wrapperClass)}>
      <section className="w-full bg-neutral-100 px-7 py-8 @container @lg:py-16">
        <div className="mx-auto flex w-full max-w-screen-xl flex-col items-center gap-4 @lg:gap-16 @3xl:flex-row">
          <div className="shrink-0">
            {!!image && (
              <Image
                src={image}
                width={300}
                height={300}
                alt=""
                className="h-[200px] w-[212px] @lg:h-[312px] @lg:w-[330px]"
              />
            )}
          </div>
          <div>
            {!!title && <TitleAs className="title5 text-center @3xl:text-left">{title}</TitleAs>}
            {!!subtitle && <p className="paragraph1 mt-4 text-center @lg:mt-5 @3xl:text-left">{subtitle}</p>}
            <div className="mt-5 flex flex-col items-center @lg:mt-11 @lg:flex-row">
              {displayInput && (
                <Input
                  placeholder={inputPlaceholder ?? ""}
                  onChange={event => onChange?.(event.target.value)}
                  className={classNames(
                    "max-w-[500px] rounded-[200px] border border-neutral-300 px-5 py-4 @lg:h-16 @lg:pr-[132px]",
                    inputClass,
                  )}
                />
              )}
              {!!ctaText && (
                <Button
                  as="button"
                  className={classNames(
                    "min-h-[50px] w-full @lg:h-16 @lg:w-auto @lg:md:min-h-[55px] @xl:xl:min-h-[64px]",
                    displayInput && "mt-3 @lg:ml-[-100px] @lg:mt-0",
                    ctaClass,
                  )}
                  disabled={!!disabled}
                  onClick={() => onPress?.()}>
                  {ctaText}
                </Button>
              )}
            </div>
            {!!errorText && <p className="paragraph1 mt-2 text-red-700">{errorText}</p>}
            {showDisclaimer && <p className="label3 mt-2 text-neutral-800">{disclaimerText}</p>}
          </div>
        </div>
      </section>
    </div>
  )
}

export default NewsletterBanner
